import React from 'react'
import { generatePath } from 'react-router-dom'
import { ColumnDef } from '@tanstack/react-table'
import numeral from 'numeral'

import { CompaniesApiResponse, Company } from 'src/ApiResponseTypes'
import { BaseTableProps } from 'src/components/Table/BaseTable'
import ConnectedTable from 'src/components/Table/ConnectedTable'
import { ColumnPreset } from 'src/components/Table/utils'
import { API, Path } from 'src/config/paths'
import { useLocales } from 'src/providers/LocaleProvider'
import Link from 'src/ui/Link'
import Tag from 'src/ui/Tag'
import { PERCENTAGE_NUMERAL_FORMAT } from 'src/utils/formatting'

import LoadCompaniesToExcel from './LoadCompaniesToExcel'

const DATA_URL = `${API}/api/companies`

export const getCompanyColumns = (moneyFormat: string): Array<ColumnDef<Company>> => {
  return [
    {
      id: 'label',
      accessorKey: 'label',
      header: 'Name',
      cell: ({ row: { original: data } }) => (
        <Link to={generatePath(Path.COMPANY, { companyId: data.business_id })}>
          {data.label}
        </Link>
      ),
      minSize: 200,
      meta: {
        flex: 2
      }
    },
    {
      id: 'risk',
      accessorKey: 'max_risk',
      header: 'Risk',
      cell: (item) => item.getValue()
        ? <Tag variation="danger">{numeral(item.getValue()).format(PERCENTAGE_NUMERAL_FORMAT)}</Tag>
        : '',
      meta: { flex: 1 }
    },
    {
      id: 'readiness',
      accessorKey: 'max_readiness',
      header: 'Readiness',
      cell: (item) => item.getValue()
        ? <Tag variation="success">{numeral(item.getValue()).format(PERCENTAGE_NUMERAL_FORMAT)}</Tag>
        : '',
      meta: { flex: 1 }
    },
    {
      id: 'industry',
      accessorFn: (row) => row.industry.label,
      header: 'Industry',
      enableSorting: false,
      meta: { flex: 1 }
    },
    {
      id: 'turnover',
      accessorKey: 'turnover',
      header: 'Revenue',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: (item) => `${numeral(item.getValue()).format(moneyFormat)}`
    },
    {
      id: 'sales',
      accessorKey: 'sales',
      header: 'Net sales (LTM)',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: (item) => `${numeral(item.getValue()).format(moneyFormat)}`
    },
    {
      id: 'potential',
      accessorKey: 'potential',
      header: 'Potential',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      cell: (item) => `${numeral(item.getValue()).format(moneyFormat)}`
    },
    {
      id: 'projection',
      accessorKey: 'projection',
      header: 'Projected',
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta },
      enableSorting: false,
      cell: (item) => `${numeral(item.getValue()).format(moneyFormat)}`
    }
  ]
}

type CompaniesTableProps = Omit<BaseTableProps<Company>, 'table'> & {
  title?: string
}

const CompaniesTable: React.FC<CompaniesTableProps> = ({ title, ...rest }: CompaniesTableProps) => {
  const { moneyFormat } = useLocales()
  const columns: Array<ColumnDef<Company>> = React.useMemo(() => getCompanyColumns(moneyFormat), [])

  return <ConnectedTable<CompaniesApiResponse, Company>
    id="companies"
    dataUrl={DATA_URL}
    columns={columns}
    responseKey="companies"
    title={title ?? 'Companies'}
    secondaryActions={<LoadCompaniesToExcel />}
    initialState={{ sorting: [{ id: 'label', desc: false }] }}
    {...rest}/>
}

export default CompaniesTable
