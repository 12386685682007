import { BusinessGroup, Company, Offering } from 'src/ApiResponseTypes'

export const getWhitespaceColumn = (entity: BusinessGroup | Offering, row: Company): JSX.Element => {
  const classes = ['color-graded']
  let title = ''

  const company = row.label
  const bg = entity.label

  if (row.sales !== null && row.sales <= 0) {
    classes.push('no-sales')
    title = `No sales in ${bg} for ${company}`
  }

  if (row.potential !== null && row.potential <= 0) {
    classes.push('no-potential')
    title = `No potential in ${bg} for ${company}`
  }

  if (row.sales !== null && row.potential !== null && row.sales < row.potential) {
    classes.push('more-potential')
    title = `More potential in ${bg} for ${company}`
  }

  if (row.sales !== null && row.potential !== null && row.sales > row.potential) {
    classes.push('more-sales')
    title = `More sales in ${bg} for ${company}`
  }

  return (
    <span
      title={title}
      className={classes.join(' ')}/>
  )
}
