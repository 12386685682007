import React from 'react'
import ReactSelect, { StylesConfig } from 'react-select'
import { DefaultTheme, useTheme } from 'styled-components'

import { SelectedFilter, SelectedFilterValue } from 'src/providers/FiltersProvider'

import { Filter, FilterOption } from './types'

// TODO:
// Allow overriding the 'selected' state styles (to not be dark purple)
// Slim version

// export const selectStyles: StylesConfig<any, any, any> = {
export const selectStyles = (theme: DefaultTheme, slim?: boolean): StylesConfig<any, any, any> => ({
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    padding: '5px'
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    padding: slim ? '1px 10px' : '10px 10px',
    borderRadius: '30px',
    background: state.hasValue ? theme.brandPurple : theme.contentBackground,
    color: state.hasValue ? theme.invertText : theme.text,
    borderColor: state.isFocused ? theme.brandPink : theme.borderColor,
    boxShadow: 'none',
    ':hover': {
      borderColor: theme.brandPink
    }
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    fontSize: '14px'
  }),
  container: (baseStyles) => ({
    ...baseStyles,
    background: theme.contentBackground,
    color: theme.text
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    fontSize: '14px',
    background: theme.brandPurple,
    color: state.hasValue ? theme.invertText : theme.text
  }),
  indicatorsContainer: (baseStyles, state) => ({
    ...baseStyles,
    svg: {
      fill: state.hasValue ? theme.bright : theme.borderColor
    }
  }),
  indicatorSeparator: (baseStyles) => ({
    ...baseStyles,
    background: theme.borderColor
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    fontSize: '14px',
    padding: '15px 10px',
    background: state.isFocused ? theme.appBackground : theme.contentBackground,
    color: theme.text,
    ':active': {
      background: theme.appBackground
    }
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    borderRadius: '10px',
    overflow: 'hidden',
    background: theme.contentBackground,
    color: theme.text
  })
})

type SelectProps = {
  filter: Filter,
  onChange: (changeObject: SelectedFilter) => void,
  onClear: (filterName: string) => void,
  selection?: SelectedFilterValue,
  isClearable?: boolean,
  slim?: boolean
}

const Select: React.FC<SelectProps> = ({ filter: { name, options }, onChange, onClear, selection, isClearable, slim, ...rest }: SelectProps) => {
  const theme = useTheme()
  const handleSelectChange = React.useCallback((option: FilterOption | null) => {
    if (option) {
      onChange({
        name,
        value: option?.value
      })
    } else {
      onClear(name)
    }
  }, [])

  // React select wants an option object as the selected value, so let's search if from filter options
  const selectedOption = React.useMemo(() => options.find(option => option.value === selection), [selection, options])

  return (
    <div {...rest}>
      <ReactSelect<FilterOption>
        menuPortalTarget={document.body}
        value={selectedOption || null }
        options={options}
        onChange={handleSelectChange}
        isClearable={isClearable}
        styles={selectStyles(theme, slim)}
      />
    </div>
  )
}

export default Select
