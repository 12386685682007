import React from 'react'
import { ColumnDef } from '@tanstack/react-table'

import { BusinessGroup, CompaniesApiResponse, Company, Offering } from 'src/ApiResponseTypes'
import { getCompanyColumns } from 'src/components/CompaniesTable/CompaniesTable'
import LoadCompaniesToExcel from 'src/components/CompaniesTable/LoadCompaniesToExcel'
import ConnectedTable from 'src/components/Table/ConnectedTable'
import { ColumnPreset } from 'src/components/Table/utils'
import { API } from 'src/config/paths'
import { useLocales } from 'src/providers/LocaleProvider'

import { getWhitespaceColumn } from '../offerings/utils'

const DATA_URL = `${API}/api/companies`

type CustomersTableProps = {
  entity: BusinessGroup | Offering
}

const CustomersTable: React.FC<CustomersTableProps> = ({ entity }: CustomersTableProps) => {
  const { moneyFormat } = useLocales()
  const columns: Array<ColumnDef<Company>> = [...getCompanyColumns(moneyFormat),
    {
      id: 'offering_whitespace',
      accessorFn: (row) => row,
      cell: ({ row: { original: row } }) => getWhitespaceColumn(entity, row),
      header: 'Whitespace',
      enableSorting: false,
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta }
    }
  ]

  return <ConnectedTable<CompaniesApiResponse, Company>
    id="business-groups-customers"
    dataUrl={DATA_URL}
    columns={columns}
    responseKey="companies"
    title="Customers"
    showFilters
    secondaryActions={<LoadCompaniesToExcel />}
  />
}

export default CustomersTable
