import React, { SVGProps } from 'react'
import {
  Bar,
  BarChart as RechartsBarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { DefaultTheme, useTheme } from 'styled-components'

const LABEL_STYLES = {
  fontSize: 13,
  fontFamily: 'Roboto Flex',
  fontWeight: 400,
  fill: '#333333'
}

export const getLabelStyles = (theme: DefaultTheme): SVGProps<SVGTextElement> => ({
  ...LABEL_STYLES,
  fill: theme.graphLabelText
})

type BarChartProps = {
  dataPoints: any[],
  data: any[],
  yFormatter: (value: number) => string,
  xFormatter: (value: string) => string,
  tooltipFormatter: (value: string) => string,
  legendFormatter: (value: string) => string,
  customTooltip?: JSX.Element
}

const BarChart: React.FC<BarChartProps> = ({
  dataPoints,
  data,
  yFormatter,
  xFormatter,
  legendFormatter,
  tooltipFormatter,
  customTooltip
}: BarChartProps) => {
  const theme = useTheme()

  return (
    <ResponsiveContainer
      width='100%'
      minHeight='350px'
    >
      <RechartsBarChart
        data={data}
        barCategoryGap={12}
        barGap={4}
        margin={{ top: 0, left: 10 }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis
          dataKey='xTick'
          tickFormatter={xFormatter}
          tick={getLabelStyles(theme)}
        />

        <YAxis
          type='number'
          tickSize={0}
          tickFormatter={yFormatter}
          tick={getLabelStyles(theme)}
          scale="linear"
          interval="preserveStartEnd"
          domain={['dataMin', 'dataMax']}
        />
        {customTooltip
          ? <Tooltip
            formatter={tooltipFormatter}
            content={customTooltip}
            cursor={{ fill: theme.text, opacity: 0.1 }}
          />
          : <Tooltip formatter={tooltipFormatter} />}
        <Legend formatter={legendFormatter} />
        {dataPoints.map((point, index) => (
          <Bar
            key={point}
            dataKey={point}
            fill={theme.dataColors[index]}
          />
        ))}
      </RechartsBarChart>
    </ResponsiveContainer>
  )
}

export default BarChart
