import React from 'react'
import ReactDropdown from 'rc-dropdown'
import { DropdownProps } from 'rc-dropdown/lib/Dropdown'
import styled from 'styled-components'

import Icon from 'src/components/Icon'

import 'rc-dropdown/assets/index.css'

const StyledReactDropdown = styled(ReactDropdown)`
  cursor: pointer;
`

type CustomDropdownProps = {
  overlay: DropdownProps['overlay']
}

const Dropdown: React.FC<CustomDropdownProps> = ({ overlay }: CustomDropdownProps) => {
  return (
    <StyledReactDropdown
      overlay={overlay}
      placement="bottomLeft"
      trigger={['click']}>
      <Icon name="Threedot" />
    </StyledReactDropdown>
  )
}

export default Dropdown
