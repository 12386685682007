import { IconProps } from '../components/Icon'

export const API = process.env.REACT_APP_API_URL ?? ''

export enum Path {
  KPI = '/',

  MARKET_ANALYSIS = '/market-analysis',
  MARKET_ANALYSIS_TAB = '/market-analysis/:tabId',

  BUSINESS_GROUPS = '/business-groups',
  BUSINESS_GROUP = '/business-groups/:businessGroupId',
  BUSINESS_GROUP_TAB = '/business-groups/:businessGroupId/:tabId',

  OFFERINGS = '/offerings',
  OFFERING = '/offerings/:offeringId',
  OFFERING_TAB = '/offerings/:offeringId/:tabId',

  INDUSTRIES = '/industries',
  INDUSTRY = '/industries/:industryId',

  COMPANIES = '/companies',
  COMPANY = '/companies/:companyId',
  COMPANY_TAB = '/companies/:companyId/:tabId',

  SETTINGS = '/settings'
}

export type SubNavigationItem = {
  path: Route,
  label: string,
  icon?: IconProps['name']
}

export type NavigationItem = {
  path?: Route,
  label: string,
  icon?: IconProps['name'],
  children?: SubNavigationItem[]
}

export type RouteKeys = keyof typeof Path
export type Route = (typeof Path)[RouteKeys]

export const SITEMAP: NavigationItem[] = [
  {
    path: Path.KPI,
    label: 'Home',
    icon: 'House'
  },
  {
    path: Path.COMPANIES,
    label: 'Companies',
    icon: 'Building'
  }, /* ,  {
    path: Path.INDUSTRIES,
    label: 'Industries',
    icon: 'Earth'
  } */
  {
    label: 'Market Analysis',
    children: [
      {
        path: Path.MARKET_ANALYSIS,
        label: 'Advanced Filtering',
        icon: 'Analytics'
      }
    ]
  },
  {
    label: 'Offering Analysis',
    children: [
      {
        path: Path.BUSINESS_GROUPS,
        label: 'Business units',
        icon: 'Shapes'
      },
      {
        path: Path.OFFERINGS,
        label: 'Offerings Groups',
        icon: 'Folder'
      }
    ]
  }
]
