type CustomTheme = {
  name: string,
  breakpoints: string[],
  borderRadius: string,

  menuIcon: string,
  buttonPrimaryBg: string,
  buttonPrimaryText: string,
  buttonPrimaryBorder: string,

  buttonPrimaryHoverBg: string,
  buttonPrimaryHoverText: string,
  buttonPrimaryHoverBorder: string,

  buttonSecondaryBg: string,
  buttonSecondaryText: string,
  buttonSecondaryBorder: string,

  buttonSecondaryHoverBg: string,
  buttonSecondaryHoverText: string,
  buttonSecondaryHoverBorder: string,

  buttonTrietaryBg: string,
  buttonTrietaryText: string,
  buttonTrietaryBorder: string,

  buttonTrietaryHoverBg: string,
  buttonTrietaryHoverText: string,
  buttonTrietaryHoverBorder: string,

  buttonDisabledBg: string,
  buttonDisabledText: string,
  buttonDisabledBorder: string,

  navLabel: string,
  navBackground: string,

  navItemText: string,
  navItemActiveText: string,
  navItemHoverText: string,

  navItemHoverBg: string,
  navItemActiveBg: string,

  scrollbarThumb: string,
  scrollbarTrack: string,
  scrollbarCorner: string,

  brandPink: string,
  brandPurple: string,
  secondary: string,
  success: string,
  danger: string,
  warning: string,
  info: string,
  bright: string,
  text: string,
  graphLabelText: string,
  invertText: string,
  secondaryText: string,
  appBackground: string,
  kpiBackground: string,
  contentBackground: string,
  borderColor: string,
  borderColorStrong: string,
  borderStrongWidth: string,

  selectedRow: string,
  selectedRowBorder: string,
  dataColors: string[]
}

const BREAKPOINT_SMALL = '800px'
const BREAKPOINT_MEDIUM = '1100px'
const BREAKPOINT_LARGE = '1500px'

export const LIGHT_THEME: CustomTheme = {
  name: 'LIGHT',
  breakpoints: [
    BREAKPOINT_SMALL,
    BREAKPOINT_MEDIUM,
    BREAKPOINT_LARGE
  ],
  borderRadius: '12px',

  scrollbarThumb: '#cdcdcd',
  scrollbarTrack: '#ffffff',
  scrollbarCorner: '#F9F5FB',

  menuIcon: '#9B00D5',
  buttonPrimaryBg: '#9B00D5',
  buttonPrimaryText: '#ffffff',
  buttonPrimaryBorder: '#9B00D5',

  buttonPrimaryHoverBg: '#BA00FF',
  buttonPrimaryHoverText: '#ffffff',
  buttonPrimaryHoverBorder: '#BA00FF',

  buttonSecondaryBg: 'transparent',
  buttonSecondaryText: '#9B00D5',
  buttonSecondaryBorder: '#9B00D5',

  buttonSecondaryHoverBg: '#ffffff',
  buttonSecondaryHoverText: '#BA00FF',
  buttonSecondaryHoverBorder: '#BA00FF',

  buttonTrietaryBg: 'transparent',
  buttonTrietaryText: '#000000',
  buttonTrietaryBorder: 'transparent',

  buttonTrietaryHoverBg: 'transparent',
  buttonTrietaryHoverText: '#BA00FF',
  buttonTrietaryHoverBorder: 'transparent',

  buttonDisabledBg: '#cdcdcd',
  buttonDisabledText: '#888888',
  buttonDisabledBorder: 'transparent',

  navBackground: '#ffffff',
  navLabel: '#444444',

  navItemText: '#333333',
  navItemHoverText: '#9B00D5',
  navItemHoverBg: 'transparent',
  navItemActiveText: '#9B00D5',
  navItemActiveBg: '#faecff',

  brandPink: '#BA00FF',
  brandPurple: '#6000c6',

  secondary: '#333333',
  success: '#0E6348',
  danger: '#BD1746',
  warning: '#FAC73C',
  info: '#165bba',
  bright: '#fefefe',

  text: '#333333',
  graphLabelText: '#666666',
  invertText: '#ffffff',
  secondaryText: '#666666',

  appBackground: '#F9F5FB',
  kpiBackground: '#FFFFFF',
  contentBackground: '#FFFFFF',
  borderColor: '#C3C3C3',
  borderColorStrong: '#C3C3C3',
  borderStrongWidth: '1px',

  selectedRow: '#F3E5F5',
  selectedRowBorder: '#C73CFA',
  dataColors: [
    '#175BBA',
    '#C73CFA',
    '#ECB7FF',
    '#F8E5FF',
    '#6000C6',
    '#000000'
  ]
}

export const DARK_THEME: CustomTheme = {
  name: 'DARK',
  breakpoints: [
    BREAKPOINT_SMALL,
    BREAKPOINT_MEDIUM,
    BREAKPOINT_LARGE
  ],
  borderRadius: '8px',

  menuIcon: '#E8AAFF',
  scrollbarThumb: '#9B00D5',
  scrollbarTrack: '#333333',
  scrollbarCorner: '#F9F5FB',

  buttonPrimaryBg: '#9B00D5',
  buttonPrimaryText: '#ffffff',
  buttonPrimaryBorder: '#9B00D5',

  buttonPrimaryHoverBg: '#BA00FF',
  buttonPrimaryHoverText: '#ffffff',
  buttonPrimaryHoverBorder: '#BA00FF',

  buttonSecondaryBg: 'transparent',
  buttonSecondaryText: '#E8AAFF',
  buttonSecondaryBorder: '#E8AAFF',

  buttonSecondaryHoverBg: '#3F3340',
  buttonSecondaryHoverText: '#E8AAFF',
  buttonSecondaryHoverBorder: '#E8AAFF',

  buttonTrietaryBg: 'transparent',
  buttonTrietaryText: '#D3D3D3',
  buttonTrietaryBorder: 'transparent',

  buttonTrietaryHoverBg: 'transparent',
  buttonTrietaryHoverText: '#E8AAFF',
  buttonTrietaryHoverBorder: 'transparent',

  buttonDisabledBg: '#333333',
  buttonDisabledText: '#999999',
  buttonDisabledBorder: 'transparent',

  navItemActiveBg: '#4d4d4d',
  navItemActiveText: '#E8AAFF',
  navBackground: '#333333',
  navLabel: '#cdcdcd',

  navItemText: '#cdcdcd',
  navItemHoverText: '#E8AAFF',
  navItemHoverBg: 'transparent',

  brandPink: '#BA00FF',
  brandPurple: '#6000c6',
  secondary: '#888888',
  success: '#2cb78b',
  danger: '#d83a66',
  warning: '#FAC73C',
  info: '#165bba',
  bright: '#fefefe',
  text: '#ffffff',

  graphLabelText: '#cdcdcd',
  invertText: '#fefefe',
  secondaryText: '#F1F1F1',
  appBackground: '#222222',
  kpiBackground: '#333333',
  contentBackground: '#333333',
  borderColor: '#666666',
  borderColorStrong: '#666666',
  borderStrongWidth: '1px',

  selectedRow: '#3d1e48',
  selectedRowBorder: '#C73CFA',
  dataColors: [
    '#9620E5',
    '#C73CFA',
    '#ECB7FF',
    '#F8E5FF',
    '#6000C6',
    '#ffffff'
  ]
}
