import {
  ColumnDef,
  ColumnMeta,
  ColumnResizeMode,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowModel,
  Table
} from '@tanstack/react-table'
import styled from 'styled-components'

import Checkbox from 'src/ui/Checkbox'

export const ROW_HEIGHT = 42

type ColumnSettings = {
  defaultColumn: DefaultColumn,
  columnResizeMode: ColumnResizeMode,
  getCoreRowModel: (table: Table<unknown>) => () => RowModel<unknown>,
  getSortedRowModel: (table: Table<unknown>) => () => RowModel<unknown>,
  getFilteredRowModel: (table: Table<unknown>) => () => RowModel<unknown>,
  getExpandedRowModel: (table: Table<unknown>) => () => RowModel<unknown>,
  getPaginationRowModel: (table: Table<unknown>) => () => RowModel<unknown>
}

type DefaultColumn = {
  minSize: number,
  maxSize: number,
  size: number
}

export const getDefaultTableSettings = (): ColumnSettings => ({
  defaultColumn: {
    minSize: 10,
    size: 80,
    maxSize: 9999
  },
  columnResizeMode: 'onChange',
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel(),
  getFilteredRowModel: getFilteredRowModel(),
  getExpandedRowModel: getExpandedRowModel(),
  getPaginationRowModel: getPaginationRowModel()
})

type Meta = Pick<ColumnDef<any>, 'id' | 'header' | 'cell' | 'size'> & {
  meta?: ColumnMeta<unknown, unknown>
}

export const ActionCol = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`

type ColumnPresets = Record<string, Meta>

export const ColumnPreset: ColumnPresets = {
  TEXT_RIGHT: {
    meta: {
      columnStyle: {
        textAlign: 'right',
        justifyContent: 'flex-end'
      },
      headerStyle: {
        textAlign: 'right',
        alignItems: 'flex-end'
      }
    }
  },
  SELECT_COLUMN: {
    header: ({ table }) => (
      <ActionCol>
        <Checkbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}/>
      </ActionCol>
    ),
    cell: ({ row }) => (
      <ActionCol>
        <Checkbox
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()} />
      </ActionCol>
    ),
    size: 36
  }
}
