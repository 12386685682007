import React from 'react'

import { useFilters } from 'src/providers/FiltersProvider'
import Button from 'src/ui/Button'

type FilterButtonProps = {
  hideWhenOpen?: boolean
}

const FilterButton: React.FC<FilterButtonProps> = ({ hideWhenOpen = false }: FilterButtonProps) => {
  const { toggleFiltersOpen, isFiltersOpen } = useFilters()

  if (hideWhenOpen && isFiltersOpen) {
    return null
  }

  return (
    <>
      <Button
        onClick={toggleFiltersOpen}
        data-testid="filters-sidebar-toggle"
        variation="tertiary"
        slim>
        {isFiltersOpen ? 'Hide Filters' : 'Show Filters'}
      </Button>
    </>
  )
}

export default FilterButton
