import React from 'react'
import { useLocalStorage } from 'react-use'

import { Currency } from 'src/ApiResponseTypes'

export type UserModel = {
  currency: Currency,
  changeCurrency: (currency: Currency) => void
}

const defaultCurrency: Currency = {
  label: 'EUR',
  external_id: 'EUR'
}

const defaultUserState: UserModel = {
  currency: defaultCurrency,
  changeCurrency: () => {}
}

const UserContext = React.createContext(defaultUserState)

export const useUserSettings = (): UserModel => React.useContext(UserContext)

type UserStateStorage = {
  currency?: Currency
}
const DEFAULT_STATE: UserStateStorage = { currency: defaultCurrency }

export const UserSettingsProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [userState, saveUserState] = useLocalStorage<UserStateStorage>('user-state', DEFAULT_STATE)
  const [selectedCurrency, setSelectedCurrency] = React.useState<Currency>(userState?.currency ?? defaultCurrency)

  const handleCurrencyChange = React.useCallback((currency: Currency) => {
    setSelectedCurrency(currency)
    saveUserState({
      currency
    })
  }, [])

  const val = {
    currency: selectedCurrency,
    changeCurrency: handleCurrencyChange
  }

  return (
    <UserContext.Provider value={val}>
      {children}
    </UserContext.Provider>
  )
}
