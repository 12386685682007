import styled, { css } from 'styled-components'
import { fontSize, FontSizeProps } from 'styled-system'

import { STYLED_CONFIG } from 'src/utils/styled-utils'

type TextProps = {
  success?: boolean,
  danger?: boolean,
  secondary?: boolean
} & FontSizeProps

const Text = styled('span').withConfig(STYLED_CONFIG)<TextProps>`

 ${props => props.danger && css`
    color: ${props.theme.danger};
  `}

  ${props => props.success && css`
    color: ${props.theme.success};
  `}

  ${props => props.secondary && css`
    color: ${props.theme.secondaryText};
  `}
  ${fontSize}
`

export default Text
