import React from 'react'
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip as RechartsTooltip, XAxis } from 'recharts'
import { useTheme } from 'styled-components'

import { BreakdownApiResponse, TurnoverGroup } from 'src/ApiResponseTypes'
import { getLabelStyles } from 'src/ui/BarChart'
import BasicTooltip from 'src/ui/BasicTooltip'

import { legendFormatter, xFormatter } from './utils'

type CustomerByTurnoverGraphProps = {
  data: BreakdownApiResponse
}

const CustomerByTurnoverGraph: React.FC<CustomerByTurnoverGraphProps> = ({
  data
}: CustomerByTurnoverGraphProps) => {
  const theme = useTheme()

  // TODO: Check if this can be done without "as keyof TurnoverGroup"
  const xLabels = Object.keys(data.turnover_groups.sales)
    .map(key => key as keyof TurnoverGroup)

  const chartData = xLabels.map((label) => {
    const sales = data.turnover_groups.sales[label] ?? 0
    const potential = data.turnover_groups.potential[label] ?? 0
    const companies = data.turnover_groups.active[label] ?? 0

    return {
      xTick: label,
      sales,
      potential: potential ?? 0,
      companies
    }
  })

  return (
    <div style={{ height: '360px' }}>
      <div style={{ height: '350px' }}>
        <ResponsiveContainer
          width='100%'
          height='100%'
        >
          <ComposedChart data={chartData} margin={{ top: 30, left: 0 }}>
            <CartesianGrid strokeDasharray='3 3' />

            <XAxis
              dataKey='xTick'
              tickFormatter={xFormatter}
              tick={getLabelStyles(theme)}
            />

            <Legend formatter={legendFormatter} />

            <Bar
              dataKey="companies"
              fill={theme.dataColors[0]}
              yAxisId="companies" />

            <Line
              type="linear"
              dataKey="sales"
              stroke={theme.dataColors[5]}
              strokeWidth={1.5}
              yAxisId="salesAndPotential"
            />

            <Line
              type="linear"
              dataKey="potential"
              stroke={theme.dataColors[1]}
              strokeWidth={1.5}
              yAxisId="salesAndPotential"
            />

            <RechartsTooltip content={<BasicTooltip />} />

          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default CustomerByTurnoverGraph
