import React from 'react'
import moment from 'moment'
import numeral from 'numeral'

import { FiltersResponse } from 'src/ApiResponseTypes'
import Select from 'src/components/Filters/Select'
import { FilterOption } from 'src/components/Filters/types'
import KpiRow from 'src/components/KpiEntry/KpiRow'
import KpiRowEntry from 'src/components/KpiEntry/KpiRowEntry'
import PageTop from 'src/components/PageTop'
import { API } from 'src/config/paths'
import useDataLoader from 'src/hooks/useDataLoader'
import { useAppState } from 'src/providers/AppStateProvider'
import { SelectedFilter } from 'src/providers/FiltersProvider'
import { useLocales } from 'src/providers/LocaleProvider'
import { useUserSettings } from 'src/providers/UserSettingsProvider'
import Box from 'src/ui/Box'
import Flex, { FlexDivider } from 'src/ui/Flex'
import PageContent from 'src/ui/PageContent'
import Text from 'src/ui/Text'

const FILTERS_URL = `${API}/api/attributes/filters`
const THEME_OPTIONS: FilterOption[] = [
  { label: 'Dark theme', value: 'DARK' }, { label: 'Light theme', value: 'LIGHT' }
]

const Settings: React.FC = () => {
  const { changeCurrency, currency: selectedCurrency } = useUserSettings()
  const { moneyFormat } = useLocales()
  const { data } = useDataLoader<FiltersResponse>(FILTERS_URL)
  const { theme, changeTheme } = useAppState()

  const handleThemeChange = React.useCallback((theme: SelectedFilter) => {
    changeTheme(theme.value)
  }, [])

  const handleCurrencyChange = React.useCallback(({ name, value }: SelectedFilter) => {
    changeCurrency({
      label: name,
      external_id: value
    })
  }, [])

  return (
    <>
      <PageTop pageTitle='Settings' />
      <PageContent>
        <>
          <Flex
            gridArea="left"
            flexDirection='column'
            overflow="visible">
            <Box flex="0 0 auto" title="About me">
              <KpiRow>
                <KpiRowEntry title="Name">
                  <span>John Smith</span>
                </KpiRowEntry>
                <KpiRowEntry title="Title">
                  <span>Sales Representative</span>
                </KpiRowEntry>
              </KpiRow>
            </Box>
            <Box flex="1 1 auto" title="180ops">
              <KpiRow>
                <KpiRowEntry title="App version">
                  <span>3.0.21</span>
                </KpiRowEntry>
                <KpiRowEntry title="CRM data updated">
                  <span>{moment().subtract(1, 'day').format('YYYY-MM-DD')}</span>
                </KpiRowEntry>
                <KpiRowEntry title="Enrichement data provider">
                  <span>Not specified</span>
                </KpiRowEntry>
                <KpiRowEntry title="Enrichement data updated">
                  <span>{moment().subtract(1, 'day').format('YYYY-MM-DD')}</span>
                </KpiRowEntry>
              </KpiRow>
            </Box>
          </Flex>
          <FlexDivider gridArea="divider" />
          <Flex gridArea="right" flexDirection='column' overflow="visible">
            <Box flex="0 0 auto" title="App settings">
              <KpiRow>
                <KpiRowEntry title="Currency">
                  <div>
                    <Select
                      slim
                      isClearable={false}
                      onChange={handleCurrencyChange}
                      onClear={() => {}}
                      selection={selectedCurrency.external_id}
                      filter={{
                        name: 'currency',
                        label: 'Currency',
                        type: 'select',
                        options: data?.currencies ? data?.currencies.map((curr) => ({ label: curr.label, value: curr.external_id })) : []
                      }}/>
                    <p style={{ textAlign: 'right' }}>
                      <Text secondary>E.g. {`${numeral(1008274.123).format(moneyFormat)}`}</Text>
                    </p>
                  </div>
                </KpiRowEntry>
                <KpiRowEntry title="Theme">
                  <Select
                    slim
                    isClearable={false}
                    onChange={handleThemeChange}
                    onClear={() => {}}
                    selection={theme.name}
                    filter={{
                      name: 'theme',
                      label: 'Theme',
                      type: 'select',
                      options: THEME_OPTIONS
                    }}/>
                </KpiRowEntry>
              </KpiRow>
            </Box>

          </Flex>
        </>
      </PageContent>
    </>
  )
}

export default Settings
