import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import { ColumnDef } from '@tanstack/react-table'
import { isEmpty } from 'lodash'
import numeral from 'numeral'

import { BreakdownApiResponse, BreakdownIndustry, BusinessGroup, Offering, OfferingApiResponse } from 'src/ApiResponseTypes'
import { createIndustryTree, IndustryRow } from 'src/components/IndustriesTable/utils'
import ConnectedKpiCards from 'src/components/KpiCard/ConnectedKpiCards'
import PenetrationGraph from 'src/components/PenetrationGraph'
import ProjectionGraph from 'src/components/ProjectionGraph'
import SalesGraph from 'src/components/SalesGraph'
import ExpandableTable from 'src/components/Table/ExpandableTable'
import useTable from 'src/components/Table/useTable'
import { ColumnPreset } from 'src/components/Table/utils'
import { API, Path } from 'src/config/paths'
import useAsyncDataLoader from 'src/hooks/useAsyncDataLoader'
import { useLocales } from 'src/providers/LocaleProvider'
import Box from 'src/ui/Box'
import Flex, { FlexDivider } from 'src/ui/Flex'
import FullPageLoadingIndicator from 'src/ui/FullPageLoadingIndicator'
import { DEFAULT_NUMERAL_FORMAT } from 'src/utils/formatting'

import { CardId } from '../KpiCard/KpiCardBase'
import KpiEntry from '../KpiEntry'
import KpiEntryWrapper from '../KpiEntry/KpiEntryWrapper'

const CARDS: CardId[] = ['active', 'passive', 'growing', 'declining']

/* const DescriptionExample: React.FC = () => (
  <div>
    <h3>This graph shows the sales penetration accross customers</h3>
    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Esse eveniet veritatis tempore distinctio nihil neque, quas voluptatibus quibusdam aliquam illum magnam, cum sunt consectetur officia temporibus vitae, asperiores reprehenderit natus.</p>
    <ul>
      <li>Lorem ipsum dolor sit amet</li>
      <li>Consectetur adipisicing elit. </li>
      <li>Quam veniam alias, aliquid unde perferendis</li>
      <li>Architecto accusamus tempore doloribus incidunt!</li>
    </ul>
  </div>
) */

type OverviewTabProps = {
  entity: BusinessGroup | Offering,
  type: 'business_groups' | 'offerings'
}

type PenetrationBreakdown = Pick<BreakdownApiResponse, 'penetration'>

const DATA_URL = `${API}/api/breakdown/`
const OverviewTab: React.FC<OverviewTabProps> = ({ type, entity }: OverviewTabProps) => {
  const { moneyFormat } = useLocales()
  const entityId = entity.external_id
  const { data, loadData } = useAsyncDataLoader<PenetrationBreakdown>(DATA_URL)

  const detailsDataUrl = `${API}/api/offering/${entityId}`
  const { data: detailsData, loadData: loadBgData } = useAsyncDataLoader<OfferingApiResponse>(detailsDataUrl)

  const industries: BreakdownIndustry[] = Object.values(entity.industries)
  const expandableList: IndustryRow[] = React.useMemo(() => createIndustryTree(industries), [data])

  const columns: Array<ColumnDef<IndustryRow>> = React.useMemo(() => [
    {
      id: 'label',
      accessorKey: 'label',
      header: 'Name',
      enableSorting: true,
      meta: { flex: 2 }
    },
    {
      id: 'sales',
      accessorKey: 'sales',
      header: 'Sales',
      enableSorting: true,
      cell: (item) => `${numeral(item.getValue()).format(moneyFormat)}`,
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta }
    },
    {
      id: 'potential',
      accessorKey: 'potential',
      header: 'Potential',
      enableSorting: true,
      cell: (item) => `${numeral(item.getValue()).format(moneyFormat)}`,
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta }
    },
    {
      id: 'projection',
      accessorKey: 'projection',
      header: 'Projected',
      enableSorting: true,
      cell: (item) => `${numeral(item.getValue()).format(moneyFormat)}`,
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta }
    },
    {
      id: 'risk',
      accessorKey: 'has_risk',
      header: () => 'At Churn risk',
      enableSorting: true,
      cell: (item) => `${numeral(item.getValue()).format(DEFAULT_NUMERAL_FORMAT)}`,
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta }
    },
    {
      id: 'readiness',
      accessorKey: 'has_readiness',
      header: () => 'Ready to buy',
      enableSorting: true,
      cell: (item) => `${numeral(item.getValue()).format(DEFAULT_NUMERAL_FORMAT)}`,
      meta: { flex: 1, ...ColumnPreset.TEXT_RIGHT.meta }
    }
  ], [])

  const { table } = useTable<IndustryRow>({
    id: 'industry-offering',
    columns,
    data: expandableList,
    getSubRows: row => row.subRows,
    getRowId: row => row.external_id,
    enableSubRowSelection: false,
    enableRowSelection: true,
    enableExpanding: true
  })

  const selectedRows = table.getState().rowSelection

  React.useEffect(() => {
    const selectedIndustries = Object.keys(selectedRows)
    const filters = {
      industries: selectedIndustries
    }
    loadData({
      breakdown: ['penetration'],
      ...filters
    })

    loadBgData(filters)
  }, [selectedRows])

  if (!data || !detailsData) {
    return <FullPageLoadingIndicator />
  }

  if (!detailsData.offering) {
    return null
  }

  const {
    external_id,
    projections,
    history,
    averages,
    active,
    sales,
    potential,
    parent
  } = detailsData?.offering

  const moneyUnit = numeral.localeData().currency.symbol
  return (
    <>
      <Flex
        gridArea="left"
        flexDirection='column'
        overflow={['visible', 'auto']}>
        <Box flex="0 0 auto">
          <ConnectedKpiCards
            cards={CARDS}
            filters={{
              ...(!isEmpty(selectedRows) ? { industries: Object.keys(selectedRows) } : {}),
              [type]: external_id
            }}/>
        </Box>

        <Box
          title={`Net sales projection (${moneyUnit})`}
          flex="0 0 auto">
          <ProjectionGraph history={history} projections={projections}/>
        </Box>

        <Box
          title={`Rolling 12 months (${moneyUnit}) billing trend and monthly Net Sales`}
          flex="0 0 auto">
          <SalesGraph history={history} averages={averages} />
        </Box>

        <Box
          title="Penetration, number of customers, net sales and average ARPA"
          flex="0 0 auto">
          <PenetrationGraph penetration={data.penetration} />
        </Box>
      </Flex>

      <FlexDivider gridArea="divider"/>

      <Flex
        flexDirection="column"
        gridArea="right"
        overflow={['visible', 'auto']}>
        <KpiEntryWrapper flex="0 0 auto">
          {parent?.external_id !== undefined && (
            <KpiEntry title="About">
              <span>
                Part of <Link to={generatePath(Path.BUSINESS_GROUP, { businessGroupId: parent?.external_id })}>{parent?.label}</Link> business group
              </span>
            </KpiEntry>
          )}
          <KpiEntry
            title="Active Customers"
            value={active}
            unit=""/>
          <KpiEntry
            title="Net sales"
            value={sales}
            unit="currency"/>
          <KpiEntry
            title="Growth potential"
            value={potential}
            unit="currency"/>
        </KpiEntryWrapper>
        <Box>
          <ExpandableTable table={table} title="Industries"/>
        </Box>
      </Flex>
    </>
  )
}

export default OverviewTab
