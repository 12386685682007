import React from 'react'

import { FiltersResponse } from 'src/ApiResponseTypes'
import { Filter } from 'src/components/Filters/types'
import { API } from 'src/config/paths'
import useDataLoader from 'src/hooks/useDataLoader'
import { FiltersProvider } from 'src/providers/FiltersProvider'
import { ReactChildren } from 'src/types'

type MarketAnalysisFiltersProps = {
  id: string,
  children: ReactChildren,
  customFilters?: Filter[],
  prependCustomFilters?: boolean
}

const MarketAnalysisFilters: React.FC<MarketAnalysisFiltersProps> = ({ id, children, customFilters, prependCustomFilters }: MarketAnalysisFiltersProps) => {
  const dataUrl = `${API}/api/attributes/filters`
  const { data } = useDataLoader<FiltersResponse>(dataUrl)

  const filters: Filter[] = React.useMemo(() => {
    if (!data) {
      return []
    }

    return [
      ...(prependCustomFilters && customFilters ? customFilters : []),
      {
        label: 'Area',
        name: 'postal_codes',
        type: 'multiselect',
        options: data.postal_codes.map((code) => ({
          label: code.label,
          value: code.external_id
        }))
      },
      {
        label: 'Business Units',
        name: 'business_groups',
        type: 'multiselect',
        options: data.business_groups.map(({ label, external_id: value }) => ({
          label,
          value
        }))
      },
      {
        label: 'Account Responsible',
        name: 'accounts',
        type: 'multiselect',
        options: data.accounts.map(({ label, external_id: value }) => ({
          label,
          value
        }))
      },
      {
        label: 'Revenue Classification',
        name: 'turnover_group',
        type: 'multiselect',
        options: data.turnover_groups.map(({ label, turnover_group_id: value }) => ({
          label,
          value
        }))
      },
      ...(!prependCustomFilters && customFilters ? customFilters : [])
    ]
  }, [data])

  return (
    <FiltersProvider
      id={id}
      initialValue={{ allFilters: filters }}>
      {children}
    </FiltersProvider>
  )
}

export default MarketAnalysisFilters
